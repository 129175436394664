import React from "react"
import { Grid, useMediaQuery, useTheme } from "@mui/material"
import styled from "@emotion/styled"

import { WpEntryTypes } from "../../common/constants"
import { useWpEntryLayout } from "../../hooks"

import { TrailerPlayer } from "../trailer_player"
import Layout from "../layout/layout"
import {
  OverlayBanner,
  CommunityPartners,
  YouMayLike,
  FilmsList,
  PanelistIndex,
  RelatedEvents,
  ScreeningLinks,
  WpEntryHeader,
  FilmDetails,
  EventDetailsCard,
  EntryDescription,
  EventItinerary,
  TicketSectionGroup,
} from "./components"
import { AdSpace } from "../ad_space"
import ImageWrapper from "../image_wrapper"
import { EventNotes } from "./components/event_notes/event_notes"

function WpEntryLayoutRoot(props) {
  const {
    entry,
    screeningToggleOptions,
    screeningEntries,
    changeActiveScreeningTo,
    getRelatedEvents,
    getBodyAdType,
    getSidebarAdType,
    getDisplayedDate,
  } = useWpEntryLayout(props)

  console.log("WpEntryLayoutRoot", entry)

  const theme = useTheme()
  const lessThanMid = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <Layout>
      <OverlayBanner data={entry} screeningEvents={screeningToggleOptions} />

      <div className="eventContent">
        <WpEntryHeader fromProgram={props.fromProgram} />

        <Grid
          container
          direction="row"
          justifyContent="center"
          spacing={0}
          className="eventContentGrid"
        >
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <article>
              {entry.trailerLink && (
                <TrailerPlayer videoUrl={entry.trailerLink} />
              )}

              {entry.includeUncroppedImageInBody && entry.posterImageMedia ? (
                <ImageWrapper
                  alt={entry.title}
                  media={entry.posterImageMedia}
                  objectFit="contain"
                  className="eventContent__uncroppedPoster"
                />
              ) : null}

              <EntryDescription htmlContent={entry.description} />
            </article>

            <EventItinerary
              detailsItinerary={entry.detailsItinerary}
              detailsNote={entry.detailsNote}
              eventFormatSlug={entry.eventFormatSlug}
              startDate={entry.startDate}
            />

            <TicketSectionGroup
              visible={entry.isEvent && lessThanMid}
              screenings={
                screeningEntries.length > 0 ? screeningEntries : [entry]
              }
              onToggleChange={changeActiveScreeningTo}
              activeScreeningId={entry.id}
              toggleOptions={screeningToggleOptions}
            />

            <EventNotes htmlContent={entry.eventNotes} />

            <EventDetailsCard
              entry={entry}
              getDisplayedDate={getDisplayedDate}
            />

            <RelatedEvents
              events={getRelatedEvents()}
              isEvent={entry.isEvent}
            />

            <FilmsList
              visible={entry?.additionalFilms?.length > 0}
              films={entry.additionalFilms}
              entryType={entry.type}
            />

            <PanelistIndex
              visible={entry.oneOf([WpEntryTypes.PANEL])}
              panelists={entry.panelists}
              sectionTitle={entry.panelistSectionTitle}
            />

            <FilmDetails
              entry={entry.isEvent && entry.mainFilm ? entry.mainFilm : entry}
            />

            {/* social media link for events */}
            <ScreeningLinks
              screeningUrls={entry.screeningUrls}
              visible={entry.isEvent}
            />

            {/* TODO: Presented By AD space */}

            <CommunityPartners
              visible={entry.isEvent}
              sponsors={entry.sponsors}
            />

            <AdSpace
              sponsor={
                entry.isEvent ? entry.advertisingSponsors.sponsorA : null
              }
              type={getBodyAdType()}
            />
          </Grid>

          <Grid item xs={0} sm={0} md={1} lg={1} />

          <Grid item xs={12} sm={12} md={3} lg={3}>
            <EntrySidebar>
              <TicketSectionGroup
                visible={entry.isEvent && !lessThanMid}
                screenings={
                  screeningEntries.length > 0 ? screeningEntries : [entry]
                }
                onToggleChange={changeActiveScreeningTo}
                activeScreeningId={entry.id}
                toggleOptions={screeningToggleOptions}
              />

              <YouMayLike entry={entry} />

              <AdSpace
                sponsor={
                  entry.isEvent ? entry.advertisingSponsors.sponsorB : null
                }
                type={getSidebarAdType()}
              />
            </EntrySidebar>
          </Grid>
        </Grid>
      </div>
    </Layout>
  )
}

export const WpEntryLayout = WpEntryLayoutRoot

export const EntrySidebar = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  row-gap: 80px;
`
