import React, { useState, useEffect } from "react"
import { Radio, Checkbox, MenuItem, ListItemText, Select } from "@mui/material"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"

export function FilterSelectItems(props) {
  const {
    label,
    items,
    type,
    multiSelect,
    filterValue,
    onFilterChange,
    filterBadges,
    setFilterBadges,
  } = props
  const isDisabled = !items.length > 0
  const [open, setOpen] = useState(false)

  const onChangeBadges = (value, filterValue, multiSelect) => {
    // filterValue is async, value is the value after select an option
    // if filterValue is undefined/null, add a new badge to filterBadge array
    if (!filterValue) {
      if (multiSelect) {
        // if multiSelect, value is an array, so the new added value is the last value in value
        setFilterBadges(prevBadges => [
          ...prevBadges,
          { type, value: value[0], multiSelect },
        ])
      } else {
        setFilterBadges(prevBadges => [
          ...prevBadges,
          { type, value, multiSelect },
        ])
      }
    } else {
      if (multiSelect) {
        // split filterValue to an array to compare filterValue & value
        filterValue = filterValue?.split(",")
        // if value array's length is longer, it means a new value is added, so add a new badge
        if (value.length > filterValue.length) {
          setFilterBadges(prevBadges => [
            ...prevBadges,
            { type, value: value[value.length - 1], multiSelect },
          ])
        } else {
          // else check which item in value is not in filterValue, that's the deselect badge
          let deselectValue = filterValue.filter(item => !value.includes(item))
          // filter the deselect value out
          setFilterBadges(prevBadges =>
            prevBadges.filter(badge => badge.value !== deselectValue[0])
          )
        }
      } else {
        // if single select, and filterValue's array length is longer
        // if value === filterValue, remove/unselect the value/badge
        if (value === filterValue) {
          setFilterBadges(prevBadges =>
            prevBadges.filter(badge => badge.value !== value)
          )
        } else {
          // else remove the current badge and add a new badge
          let updatedBadges = filterBadges.filter(badge => badge.type !== type)
          updatedBadges = [...updatedBadges, { type, value, multiSelect }]
          setFilterBadges(updatedBadges)
        }
      }
    }
  }

  const onSelectChange = e => {
    onFilterChange(e, multiSelect)
    const {
      target: { value },
    } = e
    onChangeBadges(value, filterValue, multiSelect)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  /** Close the dropdown menu whenever the background is scrolled */
  useEffect(() => {
    if (!open) {
      return
    }

    const onScroll = () => {
      setOpen(false)
    }
    window.addEventListener("scroll", onScroll)
    return () => {
      window.removeEventListener("scroll", onScroll)
    }
  }, [open])

  return (
    <Select
      disabled={isDisabled}
      value={filterValue?.split(",") || (multiSelect ? [] : "")}
      label={label}
      multiple={multiSelect}
      onChange={onSelectChange}
      renderValue={() => ""}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      IconComponent={NavigateNextIcon}
      inputProps={{ fontWeight: 700 }}
      blurOnSelect={true}
      sx={{
        "legend span": { display: "none" },
        ".MuiSvgIcon-root": {
          transform: `rotate(${open ? -90 : 90}deg)`,
          fill: "#000",
        },
        ".MuiOutlinedInput-input": { fontWeight: 700 },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          border: !isDisabled && "2px solid",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "2px solid",
        },
        "& .MuiSelect-select": {
          color: "inherit", // Ensure text color remains the same
          backgroundColor: "transparent",
        },
      }}
      MenuProps={{
        // Enable scrolling on the document when the dropdown is open
        disableScrollLock: true,
      }}
    >
      {items.sort().map(item => (
        <MenuItem
          value={item}
          disableRestoreFocus={true}
          sx={{
            "&.MuiMenuItem-root": {
              backgroundColor: "transparent",
            },
            "&.MuiMenuItem-root:hover": {
              backgroundColor: "rgba(239,239,239,0.8)",
            },
            "&.Mui-selected": {
              backgroundColor: "rgba(239,239,239,0.8)",
            },
            ".MuiButtonBase-root:hover": {
              background: "none",
            },
          }}
        >
          {multiSelect ? (
            <Checkbox
              checked={
                filterBadges.filter(badge => badge.value === item).length > 0
              }
              style={{
                color: "lightgrey",
              }}
            />
          ) : (
            <Radio
              checked={
                filterBadges.filter(badge => badge.value === item).length > 0
              }
              style={{
                color: "grey",
              }}
            />
          )}
          <ListItemText primary={item} />
        </MenuItem>
      ))}
    </Select>
  )
}
