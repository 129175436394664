import { useMemo } from "react"
import useMediaQuery from "@mui/material/useMediaQuery"

export function useMediaByBreakpoint({ desktop, tablet, mobile }) {
  const isMobile = useMediaQuery("(max-width:600px)")
  const isTablet = useMediaQuery("(max-width:800px)")

  const media = useMemo(() => {
    if (!isMobile && !isTablet) {
      return desktop
    } else if (!isMobile && isTablet) {
      // Fallback to the desktop image if there is no tablet image
      return tablet || desktop
    } else {
      // Fallback to the tablet and then desktop image if the image does not exist
      return mobile || tablet || media
    }
  }, [isMobile, isTablet])

  return media
}
