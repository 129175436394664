import React from "react"
import { useMemo } from "react"
import styled from "@emotion/styled"
import { TicketingCard } from "./ticketing_card"

const TicketSection = styled("div")`
  max-width: 26rem;

  @media (min-width: 800px) {
    max-width: unset;
    width: 100%;
  }

  @media (min-width: 1280px) {
    max-width: 1180px;
  }
`
const TicketHeader = styled("div")`
  margin-bottom: 1.5rem;

  @media (min-width: 800px) {
    margin-bottom: 0.5rem;
  }
`
const TicketTitle = styled("h2")`
  font-weight: bold;
  font-size: 1.5rem;
  margin: 0;
  padding-bottom: 0.5rem;
`
const TicketList = styled("ol")`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  justify-items: center;

  @media (min-width: 800px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
  }
`
const TicketPacksList = styled("ol")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 1rem;

  @media (min-width: 800px) {
    flex-direction: ${props =>
      props.packType === "ticketsAndPackages" ? "column" : "row"};
    flex-wrap: nowrap;
    align-items: end;
  }

  @media (min-width: 1150px) {
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1.5rem;
  }

  @media (min-width: 1280px) {
    gap: 1rem;
  }
`
const TicketNote = styled("p")`
  margin: 1rem 0;
  align-self: start;
`

const TicketCategory = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;

  @media (min-width: 800px) {
    flex-direction: row;
    align-items: end;
  }

  @media (min-width: 1150px) {
    justify-content: space-around;
  }
`

export function TicketingCardGroup(props) {
  const {
    title,
    subtitle,
    packType,
    passes,
    inPersonPasses,
    onlinePasses,
    totalItems,
    ticketingType: type,
  } = props

  const tickets = [inPersonPasses, onlinePasses]

  if (totalItems === 0) return null

  const {
    name,
    ticketingCards,
    ticketingCardsInPerson,
    ticketingCardsOnDemand,
  } = useMemo(() => {
    let name = ""
    let ticketingCards = []
    let ticketingCardsInPerson = []
    let ticketingCardsOnDemand = []

    if (packType === "festivalPasses") {
      passes.forEach(pass => {
        ticketingCards.push(
          <TicketingCard
            packType={packType}
            ticketingItem={pass}
            key={`${pass.name} ${pass.type}`}
          />
        )
      })
    } else {
      tickets.forEach(passes => {
        passes.forEach((pass, i) => {
          if (
            packType === "ticketsAndPackages" &&
            pass.ticketingType === "inPerson"
          ) {
            ticketingCardsInPerson.push(
              <TicketingCard
                packType={packType}
                ticketingItem={pass}
                key={`${pass.name} ${pass.type}`}
                cardLabel={i === 0 ? "In-Person" : null}
              />
            )
          } else if (
            packType === "ticketsAndPackages" &&
            pass.ticketingType === "online"
          ) {
            ticketingCardsOnDemand.push(
              <TicketingCard
                packType={packType}
                ticketingItem={pass}
                key={`${pass.name} ${pass.type}`}
                cardLabel={i === 0 ? "On-Demand" : null}
              />
            )
          } else {
            ticketingCards.push(
              <TicketingCard
                packType={packType}
                ticketingItem={pass}
                key={`${pass.name} ${pass.type}`}
                cardLabel={
                  i === 0
                    ? pass.ticketingType === "inPerson"
                      ? "In-Person"
                      : "On-Demand"
                    : null
                }
                buttonLabel={"See All Events"}
                linkTo="/program"
              />
            )
          }
        })
      })
    }

    return {
      name,
      ticketingCards,
      ticketingCardsInPerson,
      ticketingCardsOnDemand,
    }
  }, [packType, passes, totalItems, type])

  if (packType === "festivalPasses") {
    return (
      <TicketSection key={`${name} Ticketing Group`}>
        <TicketHeader>
          <TicketTitle>{title}</TicketTitle>
          <p>{subtitle}</p>
        </TicketHeader>

        <TicketList>{ticketingCards}</TicketList>
        <TicketNote>*All prices are in USD</TicketNote>
      </TicketSection>
    )
  } else {
    return (
      <TicketSection key={`${name} Ticketing Group`}>
        <TicketHeader>
          <TicketTitle>{title}</TicketTitle>
          <p>{subtitle}</p>
        </TicketHeader>

        {packType === "ticketsAndPackages" ? (
          <>
            <TicketPacksList packType="ticketsAndPackages">
              <TicketCategory>{ticketingCardsInPerson}</TicketCategory>
              <TicketCategory>{ticketingCardsOnDemand}</TicketCategory>
            </TicketPacksList>
          </>
        ) : (
          <TicketPacksList>{ticketingCards}</TicketPacksList>
        )}
        <TicketNote>*All prices are in USD</TicketNote>
      </TicketSection>
    )
  }
}
