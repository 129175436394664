import moment from "moment-timezone"

import { AAIFF_TIMEZONE } from "@common/constants"

/**
 * Checks if any of the discounts are currently active from the list of
 * WordPress discount objects
 *
 * @param {Array<any>} discounts a list of discount objects from WordPress
 * @returns {TicketingItem | null} the TicketingItem instance for the gold pass or null
 * if there is no gold pass
 */
function getActiveDiscounts(discounts) {
  const active = []

  if (discounts && discounts.length) {
    const today = moment().tz(AAIFF_TIMEZONE)

    // Check if the current date falls within the active periods of any of the
    // discounts
    for (let idx = 0; idx < discounts.length; idx++) {
      const currDiscount = discounts[idx]

      const startDate = moment.tz(
        `${currDiscount.startDate} 00:00`,
        "MM/DD/YYYY HH:mm",
        AAIFF_TIMEZONE
      )
      // Set the final date end time to the end of the day at 23:59
      const finalDate = moment.tz(
        `${currDiscount.finalDate} 23:59`,
        "MM/DD/YYYY HH:mm",
        AAIFF_TIMEZONE
      )

      if (today.isSameOrAfter(startDate) && today.isSameOrBefore(finalDate)) {
        // The `excludedItemNames` property is a comma-separated list of names.
        // if the list of names is available, parse it and convert it to an array of names
        if (currDiscount.excludedItemNames) {
          const formattedExcludedNames = currDiscount.excludedItemNames
            .split(",")
            .map(name => name.trim())

          active.push({
            ...currDiscount,
            excludedItemNames: formattedExcludedNames,
          })
        } else {
          // If there are no excluded item names, set it to an empty array
          // as the default value
          active.push({
            ...currDiscount,
            excludedItemNames: [],
          })
        }
      }
    }
  }

  return active
}

/**
 * A helper function to determine a ticketing item's availability depending
 * on the current date.
 *
 * - If it is before the start date, the item should be unavailable and show the
 * first day available
 * - If it is after the end date, the item should not be available
 * - If it is in the range between start and end, we use the WordPress availability
 * settings
 *
 * @param {TicketingItem} ticketingItem the TicketingItem instance to update
 * @param {Boolean} isAvailable if it is available
 * @param {String} unavailableButtonLabel the message to show on the button when unavailable
 * @param {String} [availableStart] Optional. the start of the available date range
 * @param {String} [availableEnd] Optional. the end of the available date range
 */
const setItemAvailabilityByDate = function (
  ticketingItem,
  isAvailable,
  unavailableButtonLabel,
  availableStart,
  availableEnd
) {
  const today = moment().tz(AAIFF_TIMEZONE)
  const start = availableStart
    ? moment(availableStart).tz(AAIFF_TIMEZONE)
    : null
  const end = availableEnd ? moment(availableEnd).tz(AAIFF_TIMEZONE) : null

  if (start && start.isAfter(today)) {
    ticketingItem.makeUnavailable(`Available on ${start.format("M/D")}`)
  } else if (end && end.isBefore(today)) {
    ticketingItem.makeUnavailable("No longer available")
  } else if (!isAvailable) {
    ticketingItem.makeUnavailable(unavailableButtonLabel)
  } else {
    ticketingItem.makeAvailable
  }
}

export const TicketingUtils = {
  getActiveDiscounts,
  setItemAvailabilityByDate,
}
