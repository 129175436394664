import { useStaticQuery, graphql } from "gatsby"

import { useTicketingState } from "./useTicketingState"

export function useTicketingItems() {
  const data = useStaticQuery(graphql`
    query TicketingData {
      wpFestivalPage(slug: { eq: "ticketing" }) {
        ...PageTicketingConfig
      }
    }
  `)

  const {
    wpFestivalPage: { festivalPageTicketingConfig },
  } = data

  const { ticketingItems } = useTicketingState(festivalPageTicketingConfig)

  const ticketingItemsByUiKey = {}
  ticketingItems.ticketsAndPackages.inPersonPasses.forEach(pass => {
    ticketingItemsByUiKey[pass.uiKey] = pass
  })
  ticketingItems.ticketsAndPackages.onlinePasses.forEach(pass => {
    ticketingItemsByUiKey[pass.uiKey] = pass
  })
  ticketingItems.festivalPasses.passes.forEach(pass => {
    ticketingItemsByUiKey[pass.uiKey] = pass
  })

  return ticketingItemsByUiKey
}
