import React from "react"
import { filterTypeLabels } from "../../../../common/constants"
import Chip from "@mui/material/Chip"
import ClearIcon from "@mui/icons-material/Clear"
import Stack from "@mui/material/Stack"
import useMediaQuery from "@mui/material/useMediaQuery"
import styled from "@emotion/styled"

export function FilterBadges(props) {
  const isMobile = useMediaQuery("(max-width:900px)")
  const { filterBadges, setFilterBadges, handleFilterChange, children } = props
  const handleDelete = badge => () => {
    const onFilterChange = handleFilterChange(badge.type)
    onFilterChange({ target: { value: badge.value } }, badge.multiSelect)
    let updatedBadges = filterBadges.filter(item => item.value !== badge.value)
    setFilterBadges(updatedBadges)
  }
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={3}
      useFlexGap
      sx={{ 
        ...(isMobile && {
          overflowX: "auto",
          paddingBottom: "6px", // mobile: visual separation between badges and scroll bar & match negative space above stack
        }),
      }}
      flexWrap={isMobile ? "nowrap" : "wrap"}
    >
      {!isMobile && <StyledFilterLabel>Filtered by:</StyledFilterLabel>}
      {filterBadges.map(badge => (
        <Chip
          label={`${filterTypeLabels[badge.type]}: ${badge.value}`}
          variant="outlined"
          onDelete={handleDelete(badge)}
          deleteIcon={<ClearIcon />}
          sx={{
            borderRadius: 1,
            fontWeight: "bold",
            svg: { fill: "#000" },
          }}
        ></Chip>
      ))}
      {children}
    </Stack>
  )
}

const StyledFilterLabel = styled.label`
  font-size: 13px;
  font-weight: 700;
  white-space: nowrap;
`