import { useState, useRef } from "react"

import {
  FilterTypes,
  SortOrders,
  WpEntryTypes,
  ProgramTypes,
  EventTypeSlugs,
  SearchPageTypes,
} from "../../common/constants"
import { useEntrySearch } from "./useEntrySearch"
import { useSearchQueryParams } from "./useSearchQueryParams"
import { useEntryCategories } from "./useEntryCategories"
import { SearchParamTypes } from "../../common/constants/search-params.constants"

export const useSearchState = ({
  allFilmEntries,
  allEventEntries,
  index,
  store,
  pageType,
  location,
}) => {
  const [query, setQuery] = useSearchQueryParams(
    location,
    pageType == SearchPageTypes.PROGRAM
      ? SearchParamTypes.PROGRAM_PARAMS
      : SearchParamTypes.Event_PARAMS
  )
  const [mobileOpen, setMobileOpen] = useState(false)
  const [sortOrder, setSortOrder] = useState(SortOrders.ALPHABETICAL)
  const [entries, filterOptions] = buildEntriesAndFiltersOptions()
  const [isSearchActive, searchResults] = useEntrySearch({
    searchQuery: query.search,
    activeFilters: query.filters,
    entries,
    index,
    store,
  })
  const searchContentRef = useRef()
  const searchTimeout = useRef()

  /**
   * A helper function for converting the film and event nodes into instances
   * of Entry classes. The entries are stored in a map where the keys are the wordpress ids.
   * At the same time, the filter options are created by storing all of the
   * taxonomies of both films and events.
   *
   * @returns An array containing the entries and the filter options
   */
  function buildEntriesAndFiltersOptions() {
    const entries = new Map()
    const filters = {
      [FilterTypes.FILM_FORMAT]: new Set(),
      [FilterTypes.FILM_TYPE]: new Set(),
      [FilterTypes.GENRE]: new Set(),
      [FilterTypes.LANGUAGE]: new Set(),
      [FilterTypes.COUNTRY]: new Set(),
      [FilterTypes.EVENT_FORMAT]: new Set(),
      [FilterTypes.EVENT_TYPE]: new Set(),
      // [FilterTypes.CURATED_SECTION]: new Set(),
      [FilterTypes.PROGRAM_TYPE]: new Set([
        ProgramTypes.FILMS_ONLY,
        ProgramTypes.EVENTS_ONLY,
      ]),
    }

    /**
     * Extracts filter options from the given entry. If it is a Film or Feature Screening
     * also extracts the different types of film tags
     *
     * @param {Entry} entry an entry instance to extract filter options
     */
    const extractFilterOptions = entry => {
      // Add Film related filters
      if (
        entry.type === WpEntryTypes.FILM ||
        entry.type === WpEntryTypes.FEATURE_SCREENING
      ) {
        try {
          const {
            filmFormat,
            filmTypes,
            countries,
            genres,
            languages,
            subtitledLanguages,
          } = entry

          if (filmFormat) {
            filters[FilterTypes.FILM_FORMAT].add(filmFormat)
          }

          filmTypes.forEach(type => filters[FilterTypes.FILM_TYPE].add(type))
          countries.forEach(country =>
            filters[FilterTypes.COUNTRY].add(country)
          )
          genres.forEach(genre => filters[FilterTypes.GENRE].add(genre))
          languages.forEach(language =>
            filters[FilterTypes.LANGUAGE].add(language)
          )
          subtitledLanguages.forEach(language =>
            filters[FilterTypes.LANGUAGE].add(language)
          )
        } catch (e) {
          console.error(`the entry caused an error`, entry, e)
        }
      }

      // Add non-film related Event attributes
      if (entry.isEvent) {
        const { eventType, eventTypeSlug, eventFormat } = entry

        if (eventType && eventTypeSlug !== EventTypeSlugs.FEATURE) {
          filters[FilterTypes.EVENT_TYPE].add(eventType)
        }

        if (eventFormat) filters[FilterTypes.EVENT_FORMAT].add(eventFormat)
      }

      if (entry?.screeningEventsCount > 0) {
        entry.screeningEvents.forEach(event => {
          const { eventType, eventTypeSlug, eventFormat } = event

          if (
            eventType &&
            eventTypeSlug !== EventTypeSlugs.FEATURE &&
            eventTypeSlug !== EventTypeSlugs.SHORTS_PROGRAM
          ) {
            filters[FilterTypes.EVENT_TYPE].add(eventType)
          }

          if (eventFormat) filters[FilterTypes.EVENT_FORMAT].add(eventFormat)
        })
      }
    }

    if (Array.isArray(allFilmEntries)) {
      allFilmEntries.forEach(film => {
        // Ignore any invalid films
        if (film.isValid) {
          entries.set(film.id, film)

          extractFilterOptions(film)
        }
      })
    }

    if (Array.isArray(allEventEntries)) {
      allEventEntries.forEach(event => {
        if (event && event.isValid) {
          entries.set(event.id, event)

          extractFilterOptions(event)
        } else {
          console.warn(`Event entry object is invalid: ${event}`)
        }
      })
    }
    return [entries, filters]
  }

  /**
   * A helper function that generates a setter function for each of the different
   * types of filters.
   *
   * @param {FilterTypes} filter the type of filter
   * @param {HTMLElement} e the html radio or checkbox element
   * @param {Boolean} multiSelect Optional. Indicates if the filter can take multiple values
   * @return {Function} the newly created setter function for the specified `filter`
   */
  const handleFilterChange =
    filter =>
    (e, multiSelect = false) => {
      const value = e.target.value

      switch (filter) {
        case FilterTypes.FILM_TYPE:
        case FilterTypes.FILM_FORMAT:
        case FilterTypes.COUNTRY:
        case FilterTypes.GENRE:
        case FilterTypes.LANGUAGE:
        case FilterTypes.EVENT_TYPE:
        case FilterTypes.EVENT_FORMAT:
        case FilterTypes.CURATED_SECTION:
        case FilterTypes.PROGRAM_TYPE:
          let updatedFilters = { ...query.filters }

          if (multiSelect) {
            const activeOptions = updatedFilters[filter]
              ? updatedFilters[filter].split(",")
              : null

            let newOptions
            if (typeof value === "string") {
              if (activeOptions && activeOptions.includes(value)) {
                newOptions = activeOptions.filter(el => el !== value)
              } else if (activeOptions && !activeOptions.includes(value)) {
                newOptions = activeOptions.concat([value])
              } else {
                newOptions = [value]
              }

              updatedFilters[filter] = newOptions.join(",")
            } else if (Array.isArray(value)) {
              updatedFilters[filter] = value.join(",")
            } else {
              console.error(
                "useSearchState error: received unexpected value",
                value
              )
            }
          } else {
            if (updatedFilters[filter] === value) {
              updatedFilters[filter] = null
            } else {
              updatedFilters[filter] = value
            }
          }

          setQuery(
            {
              ...query,
              filters: updatedFilters,
              categoryRef: null, // clear out category ref the user modifies the queries
            },
            "push"
          )
          break
        default:
          console.warn(`Search does not have a filter of type ${filter}`)
          break
      }
    }

  /**
   * Replaces the current active filters with the provided filters
   *
   * @param {Object} filters the filters to change to
   * @param {String} [categoryRef] Optional. The category row setting the filter
   */
  const setFilters = (filters, categoryRef) => {
    setQuery({
      ...query,
      filters: filters,
      categoryRef: categoryRef || null,
    })
  }

  /**
   * Sets the search query parameter to the new text value
   *
   * @param {String} searchText the new text value for the search query param
   */
  const setSearchQuery = searchText => {
    if (searchTimeout.current) clearTimeout(searchTimeout.current)

    searchTimeout.current = setTimeout(() => {
      setQuery(
        {
          ...query,
          search: searchText,
          categoryRef: null, // clear out category ref the user modifies the queries
        },
        "pushIn"
      )
    }, 500)
  }

  const setViewQuery = (view, removeOtherQueryParams = false) => {
    const newQuery = removeOtherQueryParams ? { view } : { ...query, view }

    setQuery(newQuery)
  }

  /**
   * Removes all active filters
   */
  const clearAllFilters = () => {
    setQuery({
      ...query,
      filters: undefined,
      categoryRef: null, // clear out category ref the user modifies the queries
    })
  }

  const eventEntries = Array.from(entries.values()).filter(
    entry => entry.isEvent
  )

  const filmEntries = Array.from(entries.values()).filter(
    entry => !entry.isEvent && entry.screeningEventsCount > 0
  )

  return {
    searchContentRef,
    searchResults,
    pageType,
    isSearchActive,
    sortOrder,
    searchQuery: query.search || "",
    activeFilters: query.filters,
    filterOptions,
    mobileOpen,
    setSortOrder,
    setSearchQuery,
    setActiveFilters: setFilters,
    handleFilterChange,
    setMobileOpen,
    getCategoryRowProps: useEntryCategories(
      eventEntries,
      setFilters,
      filmEntries
    ),
    eventEntries,
    filmEntries,
    clearAllFilters,
    setViewQuery,
    viewQuery: query?.view,
    categoryQuery: query?.categoryRef,
  }
}
