import React from "react"
import styled from "@emotion/styled"
import { ButtonLink, Button } from "@components/@aaiff"
import InnerHTML from "../inner_html/inner_html"

const Content = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 1.5rem 1rem;
  row-gap: 1.2rem;
`
const Text = styled("p")`
  font-weight: bold;
  font-size: ${props => (props.size ? props.size : "1.5rem")};
`
const TextFooter = styled("p")`
  display: flex;
  flex-direction: column;
`

export function TicketingQuestions(props) {
  const { date, htmlContent } = props
  const mailToLink = htmlContent.match(/<a[^>]*>(.*?)<\/a>/g)

  const footerTexts = [
    { size: "2.25rem", content: "See you there!" },
    { size: "2.5rem", content: `${date.format("MMMM D, YYYY")}` },
  ]

  return (
    <Content>
      <Text>Have a question?</Text>
      <ButtonLink href="/faq/">Explore the FAQ</ButtonLink>
      <Text>Contact Us</Text>
      <Button>
        <InnerHTML htmlContent={mailToLink[0]} isFestivalPage={false} />
      </Button>
      <TextFooter>
        {footerTexts.map((footer, index) => (
          <Text key={index} size={footer.size}>
            {footer.content}
          </Text>
        ))}
      </TextFooter>
    </Content>
  )
}
