import React from "react"
import useMediaQuery from "@mui/material/useMediaQuery"
import { SearchField } from "@components/@aaiff"
import styled from "@emotion/styled"

import {
  ScheduleViewTypes,
  SearchPageTypes,
} from "../../../../common/constants"
import { Link } from "../../../link"
import { ViewTypeToggle, DrawerSection } from "../filter_drawer"

const StyledSearchHeaderSection = styled.section`
  ${props =>
    props.pageType === "program" &&
    `
    margin-top: ${props.isMobile ? "1.5rem" : "2.5rem"};
    margin-bottom:  ${props.isMobile ? "2.5rem" : "4rem"};
    padding-top: 0px !important;
  `}
`

const StyledMobileSearchFilters = styled.div`
  display: flex;
  column-gap: 10px;
`
const StyledToggleFilterButton = styled.button`
  @media only screen and (max-width: 640px) {
    font-size: 0.75rem;
  }
  padding: 0;
  padding-bottom: 4px;
  color: #666666;
  white-space: nowrap;
  align-self: end;
  justify-self: end;
  text-transform: none;
  font-weight: normal;
  background: none;
  box-shadow: none;
  display: flex;
  justify-content: end;
  align-items: end;
  line-height: inherit;
  border: none;
`

const StyledFestivalProgramHeader = styled.h1`
  ${props => `
    margin-bottom: ${
      props.isMobile ? "40px" : "0px"
    }; // mobile: space before StyledMobileSearchFilters
    font-size:  ${
      props.isMobile ? "2rem" : "3rem"
    }; // mobile should really be 1.5rem per Figma, but other fonts not scaled down yet
    `}
  @media only screen and (max-width: 640px) {
    font-size: 1.5rem;
    margin-top: 40px;
  }
}
`

const DisclaimerText = styled("span")`
  font-style: italic;
`
const VisitOurText = styled("span")`
  display: inline-block;
  white-space: wrap;
  margin-top: 1rem;
`

export function SearchHeader(props) {
  const {
    pageType,
    mobileOpen,
    setMobileOpen,
    setSearchQuery,
    query,
    currentViewType,
    handleViewTypeChange,
  } = props
  const isMobile = useMediaQuery("(max-width:900px)")

  return (
    <StyledSearchHeaderSection
      className={`searchHeader searchHeader--${pageType}`}
      id="aaiff-search-header-id"
      pageType={pageType}
      isMobile={isMobile}
    >
      <div
        className="searchHeader__content"
        id="aaiff-search-header-content-id"
      >
        {pageType === SearchPageTypes.EVENTS ? (
          <article className="searchHeader__description">
            <h1>Schedule</h1>
            <p className="eventsDesc">
              View the schedule of our in-person events at this year’s Asian
              American International Film Festival.
              <VisitOurText>
                Visit our <Link to={"/program"}>Program Page</Link> to explore
                this year’s films in depth.
              </VisitOurText>
              <br />
              <br />
              <DisclaimerText>
                All times are in Eastern Daylight Time.
              </DisclaimerText>
            </p>
          </article>
        ) : (
          <StyledFestivalProgramHeader isMobile={isMobile}>
            Festival Program
          </StyledFestivalProgramHeader>
        )}

        {isMobile && pageType === SearchPageTypes.EVENTS ? (
          <DrawerSection label="View">
            <ViewTypeToggle
              currentViewType={currentViewType}
              handleViewTypeChange={handleViewTypeChange}
            />
          </DrawerSection>
        ) : null}

        {isMobile && currentViewType !== ScheduleViewTypes.GRID ? (
          <StyledMobileSearchFilters>
            <SearchField
              defaultValue={query}
              onInputChange={setSearchQuery}
              label="Search by Title or Director"
              isClearable
              pageType={pageType}
              isHidden={!isMobile || currentViewType === ScheduleViewTypes.GRID}
            />
            {isMobile ? (
              <StyledToggleFilterButton
                onClick={() => {
                  setMobileOpen(true)
                }}
              >
                Show filters
              </StyledToggleFilterButton>
            ) : null}
          </StyledMobileSearchFilters>
        ) : null}
      </div>
    </StyledSearchHeaderSection>
  )
}
