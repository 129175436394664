import { useState, useEffect, useCallback } from "react"
import { useMediaQuery } from "@mui/material"

import { TicketingItem } from "../../util/TicketingItem"
import { TicketingUtils } from "../../util/ticketing_util"

export const useTicketingState = function (ticketingPageConfig) {
  const {
    datesOfUse,
    passes = [],
    packages = [],
    passFeatures = [],
    discounts = [],
    individualTickets = [],
    additionalTextDescription: additionalInfo,
  } = ticketingPageConfig

  const [activeDiscounts, setActiveDiscounts] = useState([])
  const isDesktop = useMediaQuery("(min-width: 800px)")

  /**
   * This useEffect checks if any of the discounts are currently active based on the
   * current date. Since the discount timers are set based on New York's timezone, all
   * time comparisons are done in that timezone.
   *
   * This has to be done in a useEffect or otherwise, the day that the website is
   * built will be used instead of any user's current day
   */
  useEffect(() => {
    setActiveDiscounts(TicketingUtils.getActiveDiscounts(discounts))
  }, [])

  /**
   * A helper function that generates an object containing all of the TicketingItems
   * that will be displayed on the ticketing page. All Access items will always be
   * returned as part of this object
   *
   * This function does the following:
   * - Formats the pass and packages information from WP as TicketingItem instances
   * - Applies any active discount to the affected pass and package items
   * - Consolidates the created TicketingItems and relevant information by ticketing
   *     item type (all access, in person, online)
   *
   * The item type data to be returned is affected by the selected type
   *
   * @returns {Object} an object containing all of the TicketingItems that will be
   * displayed on the ticketing page organized by item type. All Access items
   * will always be returned as part of this object
   */

  const getTicketingItems = useCallback(
    function () {
      /**
       * A record object to keep track of the information for each
       * ticketing item type
       */
      const ticketingItemsByType = {
        allAccess: {
          passes: [],
          packs: [],
          totalItems: 0,
          settings: datesOfUse.allAccess,
        },
        inPerson: {
          passes: [],
          packs: [],
          totalItems: 0,
          settings: datesOfUse.inPerson,
        },
        online: {
          passes: [],
          packs: [],
          totalItems: 0,
          settings: datesOfUse.online,
        },
      }

      const ticketingByGroup = {
        festivalPasses: {
          passes: [],
          totalItems: 0,
        },
        ticketsAndPackages: {
          inPersonPasses: [],
          onlinePasses: [],
          totalItems: 0,
        },
        individualTickets: {
          inPersonPasses: [],
          onlinePasses: [],
          totalItems: 0,
        },
      }

      // Format the pass data from WP as TicketingItem instances
      passes.forEach(pass => {
        const {
          name,
          uiKey,
          price,
          showMissingFeatures: showUncheckedFeatures,
          description,
          type: ticketingType,
          eleventInfo,
          isHighlighted,
          isAvailable,
          unavailableButtonLabel,
          purchaseInstructions,
        } = pass

        const newPassItem = new TicketingItem({
          name,
          uiKey,
          price,
          description,
          ticketingType,
          eleventInfo,
          showUncheckedFeatures,
          isHighlighted,
          purchaseInstructions,
        })

        // Add the common pass features to each passItem
        passFeatures.forEach(feature => {
          const { description, validPassNames } = feature

          // if the `validPassNames` property doesn't include the current pass's name,
          // the feature will be unchecked
          newPassItem.addFeature(description, validPassNames.includes(name))
        })

        TicketingUtils.setItemAvailabilityByDate(
          newPassItem,
          isAvailable,
          unavailableButtonLabel,
          datesOfUse[ticketingType].ticketSaleDate,
          datesOfUse[ticketingType].finalDate
        )

        // Check if the active discount applies to the current pass. If it
        // does, apply the discount
        activeDiscounts.forEach(activeDiscount => {
          const {
            excludedItemNames = "",
            excludedItemTypes = "",
            priceReduction,
          } = activeDiscount

          if (
            !excludedItemTypes.includes("pass") &&
            !excludedItemTypes.includes(ticketingType) &&
            !excludedItemNames.includes(name)
          ) {
            newPassItem.applyDiscount(
              priceReduction.value,
              priceReduction.reductionType
            )

            newPassItem.setDiscountInfo(
              activeDiscount.name,
              activeDiscount.finalDate
            )
          }
        })

        //add setting to individual tickets
        newPassItem.setTicketSettings(datesOfUse[ticketingType])

        //add passes to festivalPasses
        ticketingByGroup["festivalPasses"].passes.push(newPassItem)
        ticketingByGroup["festivalPasses"].totalItems++

        if (ticketingItemsByType.hasOwnProperty(ticketingType)) {
          ticketingItemsByType[ticketingType].passes.push(newPassItem)
          ticketingItemsByType[ticketingType].totalItems++
        } else {
          console.warn(
            `[useTicketingState] Received invalid type ${type} for pass ${name}. Ticketing card not created`
          )
        }
      })

      // On mobile (the cards are in a column), the goldPass item should always be the first
      if (!isDesktop) {
        ticketingByGroup["festivalPasses"].passes.sort((passA, passB) => {
          if (passA.uiKey === passB.uiKey) {
            return 0
          } else if (passB.uiKey === "goldPass") {
            return 1
          } else if (passA.uiKey === "goldPass") {
            return -1
          } else {
            return 0
          }
        })
      }

      // Format the packages data from WP into TicketingItem instances
      packages.forEach(pack => {
        const { name, description, variants = [] } = pack

        // Generate an individual TicketingItem for each variant of the package
        variants.forEach(variant => {
          const {
            uiKey,
            price,
            type: ticketingType,
            eleventInfo,
            isAvailable,
            unavailableButtonLabel,
            purchaseInstructions,
          } = variant

          //Create a new ticketingItem
          const newPackItem = new TicketingItem({
            name,
            uiKey,
            price,
            description,
            ticketingType,
            eleventInfo,
          })

          newPackItem.setPriceLabel(ticketingType)
          newPackItem.setPurchaseInstructions(purchaseInstructions)

          //use the isAvailable info, and buttonlabel to add to newPackItem,
          //assuming all variants have the same info

          newPackItem.isAvailable = isAvailable
          newPackItem.unavailableButtonLabel = unavailableButtonLabel

          // treat the description field as if it is a checked feature
          newPackItem.addFeature(description, true)

          TicketingUtils.setItemAvailabilityByDate(
            newPackItem,
            isAvailable,
            unavailableButtonLabel,
            datesOfUse[ticketingType].ticketSaleDate,
            datesOfUse[ticketingType].finalDate
          )

          // Check if the active discount applies to the current package. If it
          // does, apply the discount
          // if (activeDiscount) {
          activeDiscounts.forEach(activeDiscount => {
            const {
              excludedItemNames = "",
              excludedItemTypes = "",
              priceReduction,
            } = activeDiscount

            if (
              !excludedItemTypes.includes("package") &&
              !excludedItemTypes.includes(ticketingType) &&
              !excludedItemNames.includes(name)
            ) {
              newPackItem.applyDiscountToVariant(
                priceReduction.value,
                priceReduction.reductionType
              )
              setDiscountInfo(activeDiscount.name, activeDiscount.finalDate)
            }
          })

          //add setting to individual tickets
          newPackItem.setTicketSettings(datesOfUse[ticketingType])

          ticketingByGroup["ticketsAndPackages"].totalItems++
          if (ticketingType === "inPerson") {
            ticketingByGroup["ticketsAndPackages"].inPersonPasses.push(
              newPackItem
            )
          } else if (ticketingType === "online") {
            ticketingByGroup["ticketsAndPackages"].onlinePasses.push(
              newPackItem
            )
          } else {
            console.warn(
              `[useTicketingState] Received invalid type ${ticketingType} for package ${name}. Ticketing card not created`
            )
          }
        })
      })

      //Format Individual tickets
      individualTickets.map(ticket => {
        const {
          name,
          uiKey,
          startingPrice: price,
          description,
          ticketType: ticketingType,
          isAvailable,
          unavailableButtonLabel,
        } = ticket

        const newTicketItem = new TicketingItem({
          name,
          uiKey,
          price,
          description,
          ticketingType,
        })

        newTicketItem.setPriceLabel(ticketingType)
        newTicketItem.isAvailable = isAvailable
        newTicketItem.unavailableButtonLabel = unavailableButtonLabel
        newTicketItem.addFeature(description, true)

        TicketingUtils.setItemAvailabilityByDate(
          newTicketItem,
          isAvailable,
          unavailableButtonLabel,
          datesOfUse[ticketingType].ticketSaleDate,
          datesOfUse[ticketingType].finalDate
        )

        // Check if the active discount applies to the current package. If it
        // does, apply the discount
        // if (activeDiscount) {
        activeDiscounts.forEach(activeDiscount => {
          const {
            excludedItemNames = "",
            excludedItemTypes = "",
            priceReduction,
          } = activeDiscount

          if (
            !excludedItemTypes.includes("ticket") &&
            !excludedItemTypes.includes(ticketingType) &&
            !excludedItemNames.includes(name)
          ) {
            newTicketItem.applyDiscountToVariant(
              priceReduction.value,
              priceReduction.reductionType
            )

            setDiscountInfo(activeDiscount.name, activeDiscount.finalDate)
          }
        })

        //add setting to individual tickets
        newTicketItem.setTicketSettings(datesOfUse[ticketingType])
        ticketingByGroup["individualTickets"].totalItems++
        if (ticketingType === "inPerson") {
          ticketingByGroup["individualTickets"].inPersonPasses.push(
            newTicketItem
          )
        } else if (ticketingType === "online") {
          ticketingByGroup["individualTickets"].onlinePasses.push(newTicketItem)
        } else {
          console.warn(
            `[useTicketingState] Received invalid type ${ticketingType} for package ${name}. Ticketing card not created`
          )
        }
      })

      return ticketingByGroup
    },
    [activeDiscounts, isDesktop]
  )

  return {
    ticketingItems: getTicketingItems(),
    activeDiscounts,
    additionalInfo,
  }
}
