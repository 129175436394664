import React, { useState, useEffect, useMemo } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material"
import { FilterTypes, filterTypeLabels } from "../../../../common/constants"
import { FilterByOptions } from "./filter_by_options"
import { SortByOptions } from "./sort_by_options"
import { Button } from "@components/@aaiff"
import styled from "@emotion/styled"
import { DrawerFilterHeader } from "./drawer_filter_header"
import { FilterBadges } from "./filter_badges"
import { SearchPageTypes } from "../../../../common/constants"

const programHideAllOptionItems = {
  [FilterTypes.FILM_FORMAT]: false,
  [FilterTypes.FILM_TYPE]: false,
  [FilterTypes.GENRE]: false,
  [FilterTypes.LANGUAGE]: false,
  [FilterTypes.COUNTRY]: false,
  [FilterTypes.EVENT_FORMAT]: false,
  [FilterTypes.EVENT_TYPE]: false,
  [FilterTypes.PROGRAM_TYPE]: false,
}

const eventHideAllOptionItems = {
    [FilterTypes.EVENT_FORMAT]: false,
    [FilterTypes.EVENT_TYPE]: false,
  }

const StyledBottomBarContainer = styled.div`
  padding: 0px 16px 0px 16px;
`

const StyledBottomBar = styled.div`
  border-top: 1.5px solid #000;
  overflow: hidden;
  > .MuiStack-root {
    padding: 0;
    margin-top: 16px;
    padding-bottom: ${props => props.filterBadges.length ? "16px" : "0px"};
  }
  .clearAndSortBy {
    margin-top: 12px;
    margin-bottom: 12px;
    height: 44px;
    overflow-X: auto; // for screen sizes below ~350px
    display: flex;
    align-items: center;
    .sortBy {
      flex-direction: row;
      flex-wrap: nowrap;
      label {
        margin: 0;
      }
    }

    // Allow the radio buttons for "Alphabetical" and "By Date" to take full height for click animation
    // circle to expand equally on all sides
    .MuiFormControl-root,
    .MuiFormGroup-root,
    .MuiFormControlLabel-root,
    .MuiButtonBase-root {
      height: 100%;
    }
    .clearFiltersBtn {
      white-space: nowrap;
    }
    span {
      font-size: 0.75rem;
      white-space: nowrap;
    }
    .MuiButtonBase-root {
        padding: 0px 12px 0px 12px;
    }
  }
  .filtersDrawer__applyBtn {
    margin-top: 1px; // small separation from "See results" button
    padding: 11px 0px;
    .aaiff-ButtonLabel {
      font-size: 0.875rem;
      line-height: 17.5px;
    }
  }

  .clearFiltersBtn {
    text-underline-offset: 4px;
  }

  .clearFiltersBtn > .aaiff-ButtonLabel {
    font-size: 0.75rem;
  }
`

const DialogActions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
`

const ConfirmationApplyButton = styled(Button)`
  background: #2246e9;
  width: 100%;
`

export function UniversalDrawerMobile(props) {
  const {
    mobileOpen,
    setMobileOpen,
    sortOrder,
    setSortOrder,
    filterOptions,
    activeFilters,
    setActiveFilters,
    pageType,
    filterBadges,
    setFilterBadges,
    updateBadges
  } = props

  const hideAllOptionItems = (pageType === SearchPageTypes.EVENTS) ? 
    eventHideAllOptionItems : programHideAllOptionItems;
  const [filtersToApply, setFiltersToApply] = useState(activeFilters)
  const [sortOrderToApply, setSortOrderToApply] = useState(sortOrder)
  const [showOptionItems, setShowOptionItems] = useState(hideAllOptionItems)

  /** State to track open state of confirmation modal */
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  /** Store the initial state of `props.filterBadges` */
  const initialBadges = useMemo(
    () =>
      new Set(
        filterBadges.map(badge => {
          // Normalize the badge object to compare because different
          // types of badges can have different properties, but will
          // all have `type`, `value`, and `multiSelect`
          const normalizedBadge = {
            type: badge.type,
            value: badge.value,
            multiSelect: badge.multiSelect,
          }
          return JSON.stringify(normalizedBadge)
        })
      ),
    [mobileOpen]
  )

  useEffect(() => {
    setFiltersToApply(activeFilters)
    updateBadges(activeFilters)
  }, [activeFilters])

  // Reset the filters whenever the mobile menu is closed
  useEffect(() => {
    if (!mobileOpen) {
      setFiltersToApply(activeFilters)
      setIsConfirmationOpen(false)
    }
    updateBadges(activeFilters)
  }, [mobileOpen])

  /**
   * Helper function to determine if the currently selected filter badges
   * have been updated by stringifying the badge objects and comparing them
   * to the initial state of the badges.
   * @param {Set} initialBadges The initial state of the badges
   * @param {Array} currentBadges The current state of the badges
   * @returns {boolean} Whether the badges have been updated
   */
  const areBadgesUpdated = (initialBadges, currentBadges) => {
    if (initialBadges.size !== currentBadges.length) {
      // Early return if the lengths are different
      return true
    }

    // Iterate through the current badges and compare them to the initial badges
    for (const badge of currentBadges) {
      // Normalize the badge object to compare because different
      // types of badges can have different properties, but will
      // all have `type`, `value`, and `multiSelect`
      const normalizedBadge = {
        type: badge.type,
        value: badge.value,
        multiSelect: badge.multiSelect,
      }
      if (!initialBadges.has(JSON.stringify(normalizedBadge))) {
        return true
      }
    }

    // If no differences are found, return false
    return false
  }

  const backToFilters = () => {
    setShowOptionItems(hideAllOptionItems)
  }

  const closeDrawer = () => {
    setMobileOpen(false)
    setShowOptionItems(hideAllOptionItems)
  }

  const closeDrawerIfFiltersUnchanged = () => {
    if (areBadgesUpdated(initialBadges, filterBadges)) {
      // Confirm with the user if they want to discard changes to filters
      setIsConfirmationOpen(true)
    } else {
      closeDrawer()
    }
  }

  const applyAndCloseConfirmation = () => {
    setIsConfirmationOpen(false)
    handleOnApply()
  }

  const handleOnApply = () => {
    setActiveFilters(filtersToApply)
    setSortOrder(sortOrderToApply)
    setMobileOpen(false)
    setShowOptionItems(hideAllOptionItems)
  }

  const handleClearAll = () => {
    setFiltersToApply({})
    updateBadges([])
  }

  /**
   * A helper function that generates a setter function for each of the different
   * types of filters.
   *
   * @param {FilterTypes} filter the type of filter
   * @param {HTMLElement} e the html radio or checkbox element
   * @param {Boolean} multiSelect Optional. Indicates if the filter can take multiple values
   * @return {Function} the newly created setter function for the specified `filter`
   */
  const handleFilterChange =
    filter =>
    (e, multiSelect = false) => {
      const value = e.target.value

      let updatedFilters = { ...filtersToApply }

      if (multiSelect) {
        const activeOptions = updatedFilters[filter]
          ? updatedFilters[filter].split(",")
          : null

        let newOptions
        if (activeOptions && activeOptions.includes(value)) {
          newOptions = activeOptions.filter(el => el !== value)
        } else if (activeOptions && !activeOptions.includes(value)) {
          newOptions = activeOptions.concat([value])
        } else {
          newOptions = [value]
        }

        updatedFilters[filter] = newOptions.join(",")
      } else {
        if (updatedFilters[filter] === value) {
          updatedFilters[filter] = null
        } else {
          updatedFilters[filter] = value
        }
      }

      setFiltersToApply(updatedFilters)
    }

  const showBackArrow = Object.values(showOptionItems).indexOf(true) > -1
  const openedFilter =
    filterTypeLabels[
      Object.keys(showOptionItems).find(key => showOptionItems[key])
    ]
  const showFilterTitle = showBackArrow ? openedFilter : "Filters"

  return (
    <>
      <aside
        className={`filtersDrawer _is-mobile ${
          mobileOpen ? "_is-open" : "_is-closed"
        }`}
      >
        <DrawerFilterHeader
          title={showFilterTitle}
          mobileOpen={mobileOpen}
          showBackArrow={showBackArrow}
          backToFilters={backToFilters}
          closeDrawer={closeDrawerIfFiltersUnchanged}
        />
        <div
          className={`filtersDrawer__content ${
            showBackArrow ? "optionItemsShown" : ""
          }`}
        >
          <FilterByOptions
            pageType={pageType}
            filterOptions={filterOptions}
            activeFilters={filtersToApply}
            handleFilterChange={handleFilterChange}
            mobileOpen={mobileOpen}
            showOptionItems={showOptionItems}
            setShowOptionItems={setShowOptionItems}
            filterBadges={filterBadges}
            setFilterBadges={setFilterBadges}
          />
        </div>
        <StyledBottomBarContainer>
          <StyledBottomBar
              mobileOpen={mobileOpen}
              className="filtersDrawer__bottomBar"
              filterBadges={filterBadges}
          >
              <FilterBadges
                  handleFilterChange={handleFilterChange}
                  filterBadges={filterBadges}
                  setFilterBadges={setFilterBadges}
                  activeFilters={activeFilters}
              ></FilterBadges>
              <Button className="filtersDrawer__applyBtn" onClick={handleOnApply}>
                See results
              </Button>
              <div className="clearAndSortBy">
              <Button
                  variant="ghost"
                  onClick={handleClearAll}
                  className="clearFiltersBtn"
              >
                Clear all filters
              </Button>
              <SortByOptions
                  sortOrder={sortOrderToApply}
                  setSortOrder={setSortOrderToApply}
              />
              </div>
          </StyledBottomBar>
        </StyledBottomBarContainer>
      </aside>
      <Dialog
        open={isConfirmationOpen && mobileOpen}
        onClose={() => setIsConfirmationOpen(false)}
      >
        <DialogTitle>Unsaved changes</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have unsaved changes to your filters. Would you like to apply
            them before leaving?
          </DialogContentText>
          <DialogActions className="dialogActions">
            <Button onClick={closeDrawer} variant="ghost">
              Leave without saving
            </Button>
            <ConfirmationApplyButton onClick={applyAndCloseConfirmation}>
              Apply changes
            </ConfirmationApplyButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  )
}
